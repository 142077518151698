'use strict';

var React = require("react");
var Layout = require("../components/Layout.js");
var Social = require("../components/Social.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var PageWrapper = require("../components/PageWrapper.js");
var ReactFontawesome = require("react-fontawesome");

var iconNames = /* array */[
  "envelope",
  "phone",
  "twitter",
  "github",
  "xing",
  "linkedin"
];

function Contact$ContactLink(Props) {
  var icon = Props.icon;
  var children = Props.children;
  return React.createElement("tr", undefined, React.createElement("td", undefined, React.createElement(ReactFontawesome, {
                      name: icon
                    })), React.createElement("td", undefined, children));
}

var ContactLink = {
  make: Contact$ContactLink
};

function Contact(Props) {
  return React.createElement(Layout.make, {
              children: React.createElement(PageWrapper.make, {
                    title: "Contact",
                    children: React.createElement("table", undefined, React.createElement("tbody", undefined, iconNames.map((function (iconName) {
                                    var match = Js_dict.get(Social.profiles, iconName);
                                    if (match !== undefined) {
                                      var match$1 = match;
                                      return React.createElement(Contact$ContactLink, {
                                                  icon: iconName,
                                                  children: React.createElement("a", {
                                                        href: match$1[/* url */1],
                                                        target: "_blank"
                                                      }, match$1[/* text */0]),
                                                  key: iconName
                                                });
                                    } else {
                                      return null;
                                    }
                                  }))))
                  })
            });
}

var make = Contact;

var $$default = Contact;

exports.iconNames = iconNames;
exports.ContactLink = ContactLink;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
